import dynamic from 'next/dynamic';
import useIsConsentReady from "src/lib/client/useIsConsentReady";
import styled from "src/lib/styles/css";
//#region [Styles]
const SYoutubeContainer = styled.div`
	margin: 1em auto;
`;


//#endregion

//#region [Props]
type YoutubeProps = {
    value: {
        youtubeId: string;
    }
};
//#endregion

//#region [Component]
const DynamicYouTubeEmbed = dynamic(() =>
    import('react-social-media-embed').then((mod) => mod.YouTubeEmbed),
    { ssr: false }
);

export default function Youtube({ value }: YoutubeProps) {
    const isConsentReady = useIsConsentReady()
    if (!isConsentReady || !value?.youtubeId) {
        return null;
    }

    return <SYoutubeContainer>
        <DynamicYouTubeEmbed url={`https://www.youtube.com/watch?v=${value.youtubeId}`} width="100%" />
    </SYoutubeContainer>;
}
//#endregion