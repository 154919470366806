import { Instagram } from "@filmweb/nextjs";
import useIsConsentReady from "src/lib/client/useIsConsentReady";

//#region [Props]
type InstagramProps = React.ComponentProps<typeof Instagram>
//#endregion

//#region [Component]
export default function InstagramWithConsent(props: InstagramProps) {
    const isConsentReady = useIsConsentReady();

    if (!isConsentReady) {
        return null;
    }

    return <Instagram {...props} />;
}
//#endregion

//#region [Styles]

//#endregion