import FilmwebLink from 'src/components/filmweblink/FilmwebLink';
import CmsImage from 'src/components/images/CmsImage';
import { CmsImageForUrl } from 'src/components/images/CmsImageForUrl';
import Image from "src/components/images/Image";
import CSSPoster, { CSSPosterSizer, CSSPosterWrapper, CSSPosterMissing, type SPosterMissingProps, CSSPosterMissingMovieTitle } from 'src/components/posterrow/CSSPoster';
import KinoklubbPosterBanner from 'src/kinoklubbposterbanner/KinoklubbPosterBanner';
import { type ImageVersionType, type MovieType, type StreamingContentType, type WatchableContentType } from 'src/lib/movieinfo/movieinfotypes';
import styled, { css } from 'src/lib/styles/css';
import { DocType_Filmomtale, IFilmomtale } from 'src/lib/types/Filmomtale';
import React, { useMemo } from 'react';


//#region [Props]
export type PosterProps = {
	className?: string;
	film: IFilmomtale | MovieType | StreamingContentType | WatchableContentType;
	sizes?: string;
	children?: React.ReactNode;
	posterMissingText?: string;
	showMovieTitleIfPosterIsMissing?: boolean;
	hideTitle?: boolean;
	width?: number;
};

//#endregion

//#region [Component]
export default function Poster({ film, className, sizes, posterMissingText = "Plakat mangler", showMovieTitleIfPosterIsMissing = false, hideTitle = false, width, children }: PosterProps) {
	const [_filmUrl, _hasPoster, _isKinoklubb] = useMemo<[string | null, boolean, boolean]>(() => {
		if (!film) {
			return [null, false, false];
		}
		let url = "";
		let hasPoster = false;
		let isKinoklubb = false;
		if (film?.__typename === "MovieType") {
			const f = film as MovieType;
			url = `/film/${f.mainVersionId}`
			hasPoster = !!f.sanityImagePosterUrl;
			isKinoklubb = f.isKinoklubb;
		} else if (film?.__typename === "StreamingContentType") {
			const f = film as StreamingContentType;
			url = `/streamingguide/${f.isSeries ? "serie" : "film"}/${f.id}`;
			hasPoster = !!f.sanityImagePosterUrl || (f.imagesPoster?.length ?? 0) > 0;
		} else if (film?.__typename === "WatchableContentType") {
			const f = film as WatchableContentType;
			url = film.isCinemaRelevant ?
				`/film/${f.movieId}`
				: `/streamingguide/${f.isSeries ? "serie" : "film"}/${f.streamingContentId}`;
			hasPoster = !!f.sanityImagePosterUrl || (f.imagesPosterStreaming?.length ?? 0) > 0;
		} else {
			const f = film as IFilmomtale;
			url = `/film/${f.mainVersionEDI}`
			hasPoster = (f.postersV2?.length ?? 0) > 0 || !!f.mainPosterV2;
			isKinoklubb = f.isKinoklubb;
		}
		if (!url) {
			return [null, false, false];
		}
		return [url, hasPoster, isKinoklubb];
	}, [film])

	if (!_filmUrl) return null;

	const isUnoptimized = !!width ? true : false;

	return <SPoster className={className}>
		<SPosterWrapper $isPosterMissing={!_hasPoster} $showMovieTitle={showMovieTitleIfPosterIsMissing} data-postermissingtext={posterMissingText} data-movietitle={film.title}>
			<SFilmwebLink to={_filmUrl}>
				{_hasPoster && <>
					{(film as any)?._type === DocType_Filmomtale && <SCMSPosterImage
						alt={`Plakat for '${film.title}'`}
						image={(film as IFilmomtale)?.mainPosterV2 ?? (film as IFilmomtale).postersV2?.[0]}
						sizes={sizes}
						width={width}
						unoptimized={isUnoptimized}
						isPosterAspect />}
					{(film as any)?.__typename === "MovieType" && <SCMSPosterForUrl
						imageUrl={(film as MovieType).sanityImagePosterUrl}
						alt={`Plakat for '${film.title}'`}
						sizes={sizes}
						width={width}
						unoptimized={isUnoptimized}
						isPosterAspect
						applyAspectRatio
					/>}
					{(film as any)?.__typename === "StreamingContentType" && _hasPoster && <>
						{(film as StreamingContentType).sanityImagePosterUrl && <SCMSPosterForUrl
							imageUrl={(film as StreamingContentType).sanityImagePosterUrl}
							alt={`Plakat for '${film.title}'`}
							sizes={sizes}
							width={width}
							unoptimized={isUnoptimized}
							isPosterAspect

						/>}
						{!(film as StreamingContentType).sanityImagePosterUrl && <SPosterForUrl
							image={(film as StreamingContentType).imagesPoster as ImageVersionType[]}
							alt={`Plakat for '${film.title}'`}
							unoptimized={isUnoptimized}
							sizes={sizes}
						/>}
					</>}
					{(film as any)?.__typename === "WatchableContentType" && _hasPoster && <>
						{(film as WatchableContentType).sanityImagePosterUrl && <SCMSPosterForUrl
							imageUrl={(film as WatchableContentType).sanityImagePosterUrl}
							alt={`Plakat for '${film.title}'`}
							sizes={sizes}
							width={width}
							unoptimized={isUnoptimized}
							isPosterAspect
						/>}
						{!(film as WatchableContentType).sanityImagePosterUrl && <SPosterForUrl
							image={(film as WatchableContentType).imagesPosterStreaming as ImageVersionType[]}
							alt={`Plakat for '${film.title}'`}
							unoptimized={isUnoptimized}
							sizes={sizes}
						/>}
					</>}
				</>}
				{_isKinoklubb && <KinoklubbPosterBanner />}
			</SFilmwebLink>
		</SPosterWrapper>
		{!hideTitle && <h1><FilmwebLink to={_filmUrl}>{film.title}</FilmwebLink></h1>}
		{children}
	</SPoster>;
}
//#endregion

//#region [Styles]
const SPoster = styled.article`
	${CSSPoster}
`;

const SPosterWrapper = styled.div<SPosterMissingProps>`
	${CSSPosterWrapper}

	${props => props.$isPosterMissing ? CSSPosterMissing : ""}

	${props => props.$isPosterMissing && props.$showMovieTitle ? CSSPosterMissingMovieTitle : ""}
`;

const SFilmwebLink = styled(FilmwebLink)`
	${CSSPosterSizer}
	display: flex;
	justify-content: center;
	align-items: flex-start;
`;

const CSSImageStaticHeightImage = css`
	width: 100%;
	height: auto;
`;

const SCMSPosterImage = styled(CmsImage)`
	${CSSImageStaticHeightImage}

`;

const SCMSPosterForUrl = styled(CmsImageForUrl)`
	${CSSImageStaticHeightImage}
`;

const SPosterForUrl = styled(Image)`
	${CSSImageStaticHeightImage}
`;
//#endregion