import { TikTok } from "@filmweb/nextjs";
import useIsConsentReady from "src/lib/client/useIsConsentReady";

//#region [Props]
type TikTokProps = React.ComponentProps<typeof TikTok>
//#endregion

//#region [Component]
export default function TikTokWithConsent(props: TikTokProps) {
    const isConsentReady = useIsConsentReady();

    if (!isConsentReady) {
        return null;
    }

    return <TikTok {...props} />;
}
//#endregion

//#region [Styles]

//#endregion