import { Facebook } from "@filmweb/nextjs";
import useIsConsentReady from "src/lib/client/useIsConsentReady";

//#region [Props]
type FacebookProps = React.ComponentProps<typeof Facebook>
//#endregion

//#region [Component]
export default function FacebookWithConsent(props: FacebookProps) {
    const isConsentReady = useIsConsentReady();

    if (!isConsentReady) {
        return null;
    }

    return <Facebook {...props} />;
}
//#endregion

//#region [Styles]

//#endregion