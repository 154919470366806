import { Twitter } from "@filmweb/nextjs";
import useIsConsentReady from "src/lib/client/useIsConsentReady";

//#region [Props]
type TwitterProps = React.ComponentProps<typeof Twitter>
//#endregion

//#region [Component]
export default function TwitterWithConsent(props: TwitterProps) {
    const isConsentReady = useIsConsentReady();

    if (!isConsentReady) {
        return null;
    }

    return <Twitter {...props} />;
}
//#endregion

//#region [Styles]

//#endregion