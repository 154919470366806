import styled from 'src/lib/styles/css';

//#region [Props]
type StyleCenteredProps = {
};
//#endregion

//#region [Component]
export default function StyleCentered(props: any) {
	return <SStyleCentered>{props.children}</SStyleCentered>;
}
//#endregion

//#region [Styles]
const SStyleCentered = styled.p`
	text-align: center;
`;
//#endregion