"use client";
import { useEffect, useState } from "react";

export default function useIsConsentReady(): boolean {
    const [isConsentReady, setConsentReady] = useState(false);

    useEffect(() => {
        let onConsentReady: () => void | undefined;

        // check manual consent
        if (window?.Cookiebot?.consent?.necessary) {
            setConsentReady(true);
        } else {
            onConsentReady = () => {
                setConsentReady(true);
            }
            window.addEventListener("CookiebotOnConsentReady", onConsentReady);
        }

        return () => {
            if (onConsentReady) {
                window.removeEventListener("CookiebotOnConsentReady", onConsentReady);
            }
        }
    }, []);
    return isConsentReady;

}